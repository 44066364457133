import $ from 'jquery';
import validate from 'jquery-validation';

function resetForm() {
  //alert('RESET FORM');
  $('#name').val('');
  $('#email').val('');
  $('#kleidergroesse').val('');
  $('input[name="erfahrung"]').prop('checked', false).change();
  $('input[name="beschreibung"]').prop('checked', false).change();
  $('input[name="einverstaendnis"]').prop('checked', false).change();
  $('input[class="preferences"]').prop('checked', false).change();
  $('#limits').val('');
  $('#einschraenkungen').val('');
  $('#wichtig').val('');
  $('input[name="outfit"]').prop('checked', false).change();
  $('#wunschtermin').val('');
  $('#dauer').val('');
  $('#uhrzeit').val('');
  $('#attention').val('');
  $('input[class="outfit"]').prop('checked', false).change();
  $('input[name="datenschutz"]').prop('checked', false).change();
  $('#form-messages').text('');
}

class ModuleFormular {
  init() {
    this.initListener();
    this.initTheForm();
  }

  initListener = () => {
    //$('#formularModal').show();
  };

  initTheForm() {
    resetForm();
    const formMessages = $('#form-messages');

    $("form[name='registration']").validate({
      rules: {
        name: 'required',
        email: {
          required: true,
          email: true
        },
        kleidergroesse: 'required',
        erfahrung: {
          required: true
        },
        beschreibung: {
          required: true
        },
        limits: 'required',
        outfit: {
          required: true
        },
        wunschtermin: 'required',
        dauer: 'required',
        uhrzeit: 'required',
        attention: 'required',
        einverstaendnis: {
          required: true
        },
        datenschutz: {
          required: true
        },
        policy: 'required'
      },
      messages: {
        name: 'Bitte gebe deinen Namen ein.',
        email: 'Bitte gebe deine korrekte E-Mail ein.',
        kleidergroesse: 'Bitte gebe deine Kleidergöße ein.',
        beschreibung: {
          required: 'Bitte beschreiben dich hier selber.'
        },
        erfahrung: {
          required: 'Bitte wähle deine Erfahrung aus'
        },
        limits: 'Bitte gebe deine Limits ein.',
        outfit: 'Bitte gebe deinen Outfit Wunsch aus.',
        wunschtermin: 'Bitte gebe deinen Wunschtermin ein.',
        dauer: 'Bitte gebe deine Sessiondauer ein.',
        uhrzeit: 'Bitte gebe deine Wunschzeit ein.',
        attention: 'Bitte gebe ein, wie du auf mich aufmerksam geworden bist.',
        einverstaendnis: 'Bitte akzeptiere  die Einverständniserklärung',
        datenschutz: 'Bitte akzeptiere  die Datenschutz-Bedingungen.'
      },
      errorPlacement(error, element) {
        if (element.is(':radio')) {
          error.appendTo(element.parents('.radio-button'));
        } else if (element.is(':checkbox')) {
          error.appendTo(element.parents('.checkboxes'));
        } else {
          error.insertAfter(element);
        }
      },
      submitHandler(form) {
        //form.submit();

        const formData = $(form).serialize();

        console.log(formData);

        $.ajax({
          type: 'POST',
          url: '../php/contact_form.php',
          data: formData
        })
          .done(function (response) {
            console.log('OK');

            resetForm();

            // Set the message text.
            formMessages.text(response);
          })
          .fail(function (data) {
            console.log('FAIL');

            // Set the message text.
            if (data.responseText !== '') {
              console.log(data.responseText);
              formMessages.text(data.responseText);
            } else {
              console.log('ERROR');
              formMessages.text('Oops! An error occured and your message could not be sent.');
            }
            console.log('Send FAIL 2');
          });
      }
    });
  }
}

export default ModuleFormular;
