import $ from 'jquery';
import 'jquery-scrollify';

import ModuleHeader from '../modules/moduleHeader';
import ModuleFaq from '../modules/moduleFaq';
import ModuleFooter from '../modules/moduleFooter';
import ModuleFormular from '../modules/moduleFormular';
import ModuleCaroussel from '../modules/moduleCaroussel';
import ModuleText from '../modules/moduleText';

const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;


class Homepage {
  init() {
    const moduleHeader = new ModuleHeader();
    moduleHeader.init();

    const moduleCaroussel = new ModuleCaroussel();
    moduleCaroussel.init();

    const moduleText = new ModuleText();
    moduleText.init();

    const moduleFaq = new ModuleFaq();
    moduleFaq.init();

    const moduleFooter = new ModuleFooter();
    moduleFooter.init();

    const moduleFormular = new ModuleFormular();
    moduleFormular.init();

    this.initScrollify();
    this.onResize();

    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
  }

  initScrollify() {
    return;
    console.log('initScrollify');
    $.scrollify({
      section: '.section',
      sectionName: 'section-name',
      interstitialSection: 'section_no_scrollify',
      scrollSpeed: 1000,
      setHeights: false,
      overflowScroll: true,
      updateHash: true,
      touchScroll: true,
      before() {},
      after() {}
    });
  }

  onResize() {
    $(window).on('resize', function() {
      const winWidth = $(window).width();
      const winHeight = $(window).height();
      if (IS_TOUCH_DEVICE === false) {
        $.scrollify.setOptions({ scrollSpeed: 1000 });
      } else {
        $.scrollify.setOptions({ scrollSpeed: 250 });
      }
      $.scrollify.update();

      //const carousselItemWidth = winHeight * 0.75;
      //$('.caroussel_item').css('width', `${carousselItemWidth}px`);
    });
    $(window).resize();
  }


}

export default Homepage;
