import $ from 'jquery';
import 'jquery-scrollify';

import ModuleHeader from '../modules/moduleHeader';
import ModuleFaq from '../modules/moduleFaq';
import ModuleFooter from '../modules/moduleFooter';
import ModuleFormular from '../modules/moduleFormular';
import ModuleCaroussel from '../modules/moduleCaroussel';
import ModuleText from '../modules/moduleText';
import ModuleIntro from "../modules/moduleIntro";

const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;

class Intropage {
  init() {
    const moduleIntro = new ModuleIntro()
    moduleIntro.init();

    this.onResize();

    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
  }

  onResize() {
    $(window).on('resize', function() {

    });
    $(window).resize();
  }
}

export default Intropage;
