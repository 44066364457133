import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ModuleIntro {
  init() {
    this.animate();
    this.initParallax();
  }

  animate() {

  }

  initParallax() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.header_logo_holder_', {
      scrollTrigger: {
        trigger: '#header',
        start: 'center 0%',
        end: 'center -100%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0
    });
  }
}

export default ModuleIntro;
