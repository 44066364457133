import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ModuleText {
  init() {
    this.initParallax();
    this.onResize();
  }

  initParallax() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from('.about_text_holder', {
      scrollTrigger: {
        trigger: '#about',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.session_text_holder', {
      scrollTrigger: {
        trigger: '#session',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.session_copy', {
      scrollTrigger: {
        trigger: '#session',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0
    });
    gsap.from('.questionnaire_text_holder', {
      scrollTrigger: {
        trigger: '#questionnaire',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.poster_text_holder', {
      scrollTrigger: {
        trigger: '#poster',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.tour_text_holder', {
      scrollTrigger: {
        trigger: '#tour',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.profile_text_holder', {
      scrollTrigger: {
        trigger: '#profile',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.profile_copy', {
      scrollTrigger: {
        trigger: '#profile',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0
    });
    gsap.from('.faq_text_holder', {
      scrollTrigger: {
        trigger: '#faq',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0,
      scale: 0.8
    });
    gsap.from('.faq_holder', {
      scrollTrigger: {
        trigger: '#faq',
        start: 'top 110%',
        end: 'top 20%',
        markers: false,
        scrub: 0
      },
      y: 200,
      autoAlpha: 0
    });
  }

  onResize = () => {
    $(window)
      .resize(function () {
        const posterWidth = $('.poster_text').width();
        $('.poster_image').css('width', `${posterWidth*.5  }px`);
        $('.tour_image').css('width', `${posterWidth*.5  }px`);
      })
      .resize();
  };
}

export default ModuleText;
