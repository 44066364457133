import $ from 'jquery';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ModuleFaq {
  init() {
    this.initListener();
  }

  initListener() {
    $('.faq_question').click(function () {
      const selectedId = $(this).data('id');
      const selectedAnswer = $(`.faq_answer_${selectedId}`);
      const selectedIcon = $(`.icon_${selectedId}`);
      if (selectedAnswer.css('maxHeight') !== '0px') {
        selectedAnswer.css('maxHeight', '0');
        selectedAnswer.removeClass('show');
        selectedIcon.removeClass('rotate');
      } else {
        selectedAnswer.css('maxHeight', `${selectedAnswer.prop('scrollHeight')  }px`);
        selectedAnswer.addClass('show');
        selectedIcon.addClass('rotate');
      }

      $('.faq_answer').each(function(index)
      {
        if(index !== selectedId)
        {
          $(this).css('maxHeight', '0');
          $(this).removeClass('show');
        }
      });

      $('.icon').each(function(index)
      {
        if(index !== selectedId)
        {
          $(this).removeClass('rotate');
        }
      });
    });
  }
}

export default ModuleFaq;
