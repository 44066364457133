import $ from 'jquery';

const video = $('.content_video');

function playVideo() {
  video[0].play();
}
function pauseVideo() {
  video[0].pause();
}

class ModuleFooter {
  init() {
    this.onScroll();
  }

  onScroll() {
    $(window).on('scroll', function () {
      const scrollTop = $(document).scrollTop();
      const videoYPos = $('#tour').position().top;
      console.log('scrollTop = ' + scrollTop + ' /  ' + videoYPos);

      if (scrollTop < videoYPos) {
        pauseVideo();
      } else {
        //playVideo();
      }
    });
    $(window).scroll();
  }
}

export default ModuleFooter;
