import $ from 'jquery';
import 'slick-carousel';
import CustomSlider from '../slider/customSlider';
import CustomAnimation from '../animation/customAnimation';

const customStraponSlider = new CustomSlider();
const customBiSlider = new CustomSlider();
const customFistingSlider = new CustomSlider();
const customCbtSlider = new CustomSlider();
const customDgSlider = new CustomSlider();
const customSpankingSlider = new CustomSlider();
const customFacesittingSlider = new CustomSlider();
const customLatexSlider = new CustomSlider();
const customFeedbackSlider = new CustomSlider();

const customAnimation = new CustomAnimation();

class ModuleCaroussel {
  init() {
    this.initSlider();
    this.initParallax();

    customStraponSlider.init();
  }

  initSlider() {
    customStraponSlider.initSlider(
      $('.strapon_caroussel'),
      $('.strapon_caroussel_arrow_left'),
      $('.strapon_caroussel_arrow_right')
    );
    customBiSlider.initSlider(
      $('.bi_caroussel'),
      $('.bi_caroussel_arrow_left'),
      $('.bi_caroussel_arrow_right')
    );
    customFistingSlider.initSlider(
      $('.fisting_caroussel'),
      $('.fisting_caroussel_arrow_left'),
      $('.fisting_caroussel_arrow_right')
    );
    customCbtSlider.initSlider(
      $('.cbt_caroussel'),
      $('.cbt_caroussel_arrow_left'),
      $('.cbt_caroussel_arrow_right')
    );
    customDgSlider.initSlider(
      $('.dg_caroussel'),
      $('.dg_caroussel_arrow_left'),
      $('.dg_caroussel_arrow_right')
    );
    customSpankingSlider.initSlider(
      $('.spanking_caroussel'),
      $('.spanking_caroussel_arrow_left'),
      $('.spanking_caroussel_arrow_right')
    );
    customFacesittingSlider.initSlider(
      $('.facesitting_caroussel'),
      $('.facesitting_caroussel_arrow_left'),
      $('.facesitting_caroussel_arrow_right')
    );
    customLatexSlider.initSlider(
      $('.latex_caroussel'),
      $('.latex_caroussel_arrow_left'),
      $('.latex_caroussel_arrow_right')
    );
    customFeedbackSlider.initTextSlider(
      $('.feedback_caroussel'),
      $('.feedback_caroussel_arrow_left'),
      $('.feedback_caroussel_arrow_right')
    );
  }

  initParallax() {
    customAnimation.animateCaroussel('.strapon_caroussel', '#strapon');
    customAnimation.animateCaroussel('.bi_caroussel', '#bi');
    customAnimation.animateCaroussel('.fisting_caroussel', '#fisting');
    customAnimation.animateCaroussel('.cbt_caroussel', '#cbt');
    customAnimation.animateCaroussel('.dg_caroussel', '#dg');
    customAnimation.animateCaroussel('.spanking_caroussel', '#spanking');
    customAnimation.animateCaroussel('.facesitting_caroussel', '#facesitting');
    customAnimation.animateCaroussel('.latex_caroussel', '#latex');
    customAnimation.animateCaroussel('.feedback_caroussel', '#feedback');
  }
}

export default ModuleCaroussel;
