import $ from 'jquery';

let naviIsOpen = false;
let timeout;

function openNavigation() {
  console.log('openNavigation()');
  $('#toggle').addClass('active');
  $('#mainNav').addClass('open');
  $('.logo .white').addClass('show');
  $('.logo .black').removeClass('show');

  $('#toggle').addClass('over_menu');

  naviIsOpen = true;

  timeout = setTimeout(function () {
    $('html').attr('data-modal-active', 'true');
  }, 1000);


  //document.documentElement.setAttribute(
  //  'data-modal-active',
  //  document.documentElement.getAttribute('data-modal-active') === 'true' ? 'false' : 'true'
  //);
}

function closeNavigation() {
  console.log('closeNavigation()');
  $('#mainNav').addClass('closing');

  clearTimeout(timeout);
  setTimeout(function () {
    $('#mainNav').removeClass('open');

    $('.logo .white').removeClass('show');
    $('.logo .black').addClass('show');
    $('#toggle').removeClass('active');

    $('#toggle').removeClass('over_menu');
    $('.header_icon').removeClass('over_menu');

    naviIsOpen = false;

    //document.documentElement.setAttribute(
    //    'data-modal-active',
    //    document.documentElement.getAttribute('data-modal-active') === 'true' ? 'false' : 'true'
    //);
  }, 500);

  setTimeout(function () {
    $('#mainNav').removeClass('closing');
  }, 1000);

  $('html').attr('data-modal-active', 'false');
}

class ModuleNavigation {
  init() {
    this.show();
    this.initListener();
    // this.onScroll();
    this.onResize();
  }

  show = () => {
    setTimeout(function () {
      $('.navbar')
        .delay(1500)
        .queue(function () {
          $(this).addClass('show');
          $('#toggle').addClass('show');
        });
    }, 0);
  };

  initListener = () => {
    $('#toggle').click(function () {
      if (naviIsOpen === false) {
        openNavigation();
      } else {
        closeNavigation();
      }
    });

    $('#mainNav').click(function () {
      closeNavigation();
    });

    $('.menu_item').click(function (e) {
      closeNavigation();
    });
    $('.menu_item_scroll').click(function (e) {
      e.preventDefault();
      const aid = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(aid).offset().top }, 500);
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {
        const winHeight = $(window).height();
        if (winHeight < $('.nav').outerHeight()) {
          $('#navigation').addClass('low_height');
        } else {
          $('#navigation').removeClass('low_height');
        }
      })
      .resize();
  };
}

export default ModuleNavigation;
