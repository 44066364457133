import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import ModulePreloader from './modules/modulePreloader';
import Homepage from './pages/homepage';
import ModuleNavigation from './modules/moduleNavigation';
import Intropage from "./pages/intropage";

$(document).ready(function () {
  const modulePreloader = new ModulePreloader();
  modulePreloader.init();

  setTimeout(function () {
    if ($('body').data('pagename') === 'intro') {
      const intropage = new Intropage();
      intropage.init();
    } else {
      const moduleNavigation = new ModuleNavigation();
      moduleNavigation.init();

      const homepage = new Homepage();
      homepage.init();
    }
  }, 500);
});
