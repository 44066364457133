import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const video = $('.home_video');

function playVideo() {
  video[0].play();
}
function pauseVideo() {
  video[0].pause();
}

class ModuleHeader {
  init() {
    this.initStoerer();
    this.animate();
    this.initParallax();
    this.onScroll();
  }

  initStoerer() {
    const showStoerer = $('.header_stoerer').data('show-stoerer');

    if (showStoerer === false) {
      $('.header_stoerer').addClass('hide');
    }
  }
  animate() {
    setTimeout(function () {
      $('.header_logo').addClass('show');

      setTimeout(function () {
        $('.header_stoerer').addClass('show');
      }, 1000);
    }, 1000);
  }

  initParallax() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.header_logo_holder', {
      scrollTrigger: {
        trigger: '#header',
        start: 'center 0%',
        end: 'center -100%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0
    });
  }

  onScroll() {
    $(window).on('scroll', function () {
      const scrollTop = $(document).scrollTop();
      if (scrollTop > 200) {
        pauseVideo();
      } else {
        playVideo();
      }
    });
    $(window).scroll();
  }
}

export default ModuleHeader;
