import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class CustomSlider {
  init() {}

  animateCaroussel(carousselElement, triggerElement) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(carousselElement, {
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top 100%',
        end: 'top 10%',
        markers: false,
        scrub: 0
      },
      y: -100,
      scale: 1
    });
  }
}

export default CustomSlider;
