import $ from 'jquery';

const IS_TOUCH_DEVICE = 'ontouchstart' in document.documentElement;
let mySlick;
let timeout;

class CustomSlider {
  init() {
    this.onResize();
  }

  initSlider(caroussel, arrowLeft, arrowRight) {
    const slickContainer = caroussel;

    if (IS_TOUCH_DEVICE === true) {
      $('.caroussel_arrow_left, .caroussel_arrow_right').css('display', 'none');
    }

    // slider
    mySlick = slickContainer.slick({
      slidesToShow: 3,
      centerMode: true,
      variableWidth: true,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            variableWidth: false,
            adaptiveHeight: true,
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500
          }
        }
      ]
    });

    arrowLeft.click(function () {
      slickContainer.slick('slickPrev');
    });
    arrowRight.click(function () {
      slickContainer.slick('slickNext');
    });
  }

  initTextSlider(caroussel, arrowLeft, arrowRight) {
    const slickContainer = caroussel;

    // slider
    const slick = slickContainer.slick({
      slidesToShow: 1,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      adaptiveHeight: true
    });
    // slick.on('beforeChange', function (event, slick, currentSlide) {});
    // slick.on('afterChange', function (event, slick, currentSlide) {});

    arrowLeft.click(function () {
      slickContainer.slick('slickPrev');
    });
    arrowRight.click(function () {
      slickContainer.slick('slickNext');
    });
  }

  onResize = () => {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        let imageWidth;
        let imgHeight;

        if (winWidth > 768) {
          imageWidth = ($('.caroussel_item.image').height() / 2043) * 1536;
          imgHeight = '100vh';
          imgHeight = 'calc(100vh - 50px)';
        } else {
          imageWidth = winWidth;
          imgHeight = `${(imageWidth / 1536) * 2043}px`;
        }

        $('.caroussel_item').css('width', `${imageWidth}px`);
        $('.caroussel_item').css('height', imgHeight);

        clearTimeout(timeout);
        setTimeout(function () {
          $('.caroussel_item').css('width', `${imageWidth}px`);
          $('.caroussel_item').css('height', imgHeight);
        }, 100);
      })
      .resize();
  };
}

export default CustomSlider;
